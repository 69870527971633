/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import {
  Section,
  Card,
  Container,
  Hero,
  Columns,
  Box,
  Button,
} from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Separator from 'components/separator'
import ClientQuote from 'components/client-quote'
import FloatBoxes from './FloatBoxes'

function HeroComponent({ featuredMedia, data }) {

  useEffect(() => {
    const dropdown = document.querySelector('.dropdown')
    dropdown.addEventListener('click', (event) => {
      event.stopPropagation()
      dropdown.classList.toggle('is-active')
    })
  })

  const logHeroEvent = (name, to) => {

    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', `hero_option_${name}`, { name })
      // alert(`GTag: ${  name}`)
    }
    if (typeof window !== 'undefined' && window.hj) {
      window.hj('event', `hero_option_${name}`)
      // alert(`HJ: ${  name}`)
    }

    navigate(to)
  }


  return (
    <Hero className="has-background-grey-lighter is-fullheight">
      <Hero.Header>
        <SiteNav fullLogo />
      </Hero.Header>
      <Hero.Body>
        <Container className="is-widescreen">
          <div className="columns content is-vcentered is-multiline is-centered has-text-centered mt-5 pt-5">
            <div className="column is-10-desktop is-10-widescreen is-8-tablet is-12-mobile ">
              <h1 className="title is-size-6 is-outlined pt-2 mt-0 mb-0">
                UK App Developers
              </h1>
              <h2 className="title is-size-1-widescreen is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text has-text-grey-darker">
                <dfn title="Native mobile apps, Flutter apps, web apps, iOS apps, Android apps, Desktop Apps">
                  Make apps that <br className="is-hidden-mobile" />
                </dfn>{' '}
                <span className="has-text-primary">make a difference.</span>
              </h2>

              <Columns className='is-centered'>
                <Columns.Column className='is-9-widescreen'>
                  <p className="is-size-3-widescreen is-size-3-desktop is-size-4-mobile">
                    We're an app development company that helps purpose-led brands amplify their impact with engaging mobile experiences, scalable digital platforms and data-driven growth marketing.
                  </p>
                </Columns.Column>
              </Columns>
              <p className="pb-3 is-size-4 is hidden">
                Our clients partner with us to research their markets,
                validate ideas and then get them out into the world. Then, we
                iterate. Product tuning, experiments and mobile marketing.
                This is how their apps achieve more impact every day.
              </p>

              <p className="pt-5 pb-6">

                <div class="dropdown is-hero-select is-hoverable">
                  <div class="dropdown-trigger">
                    <div className="has-shadow is-rounded">
                      <Button
                        className="button is-text-button is-large "
                        aria-haspopup="true"
                        aria-controls="dropdown-menu-hero"
                        onMouseDown={() => logHeroEvent("viewed")}
                      >
                        <span className="is-pulled-left has-text-weight-bold button-label">
                          I need help with
                        </span>
                        <span class="icon is-small is-pulled-right">
                          <i class="fas fa-angle-down" aria-hidden="true" />
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div
                    className="dropdown-menu is-large"
                    id="dropdown-menu-hero"
                    role="menu"
                  >
                    <div class="dropdown-content">
                      <Link class="dropdown-item" onMouseDown={() => logHeroEvent("clicked_sense_check", "/services/advisory/sense-check/")}>
                        A sense-check of our app ideas
                      </Link>
                      <hr class="dropdown-divider" />
                      <Link class="dropdown-item" onMouseDown={() => logHeroEvent("clicked_validate_market", "/services/advisory/app-yes-or-no/")}>
                        Validating the need for an app
                      </Link>
                      <hr class="dropdown-divider" />
                      <Link class="dropdown-item" onMouseDown={() => logHeroEvent("clicked_design", "/services/advisory/concept-design/")}>
                        Designing a visual app concept
                      </Link>
                      <hr class="dropdown-divider" />
                      <Link class="dropdown-item" onMouseDown={() => logHeroEvent("clicked_development", "/services/mobile-app-development")}>
                        Developing an app or platform
                      </Link>
                      <hr class="dropdown-divider" />
                      <Link class="dropdown-item" onMouseDown={() => logHeroEvent("clicked_growth", "/services/advisory/growth-diagnostic/")}>
                        Making our app more successful
                      </Link>



                    </div>
                  </div>
                </div>

                <Link
                  to="/services"
                  className="button is-primary is-medium is-leafy mr-3 mb-3 is-hidden"
                >
                  View our services
                </Link>

                <Link
                  to="/mobile-strategy-toolkit"
                  className="button is-text is-medium mr-3 mb-3 is-hidden"
                >
                  Mobile Success Guides →
                </Link>
              </p>
              <p className="pb-3 is-size-5 pr-6 mr-6 is-hidden">
                <sup className="is-hidden">
                  <i className="is-size-8 has-text-primary fa-solid fa-star-of-life" />
                </sup>{' '}
                <span className="tag is-grey is-size-6">New!</span> How we're
                helping 60,000 people live with diabetes, creating 900%
                revenue growth for Carbs & Cals.
              </p>
            </div>
            {/* <div className="column is-4-widescreen is-4-desktop is-4-tablet is-hidden-mobile">
                <GatsbyImage
                  image={featuredMedia.gatsbyImageData}
                  alt={featuredMedia.alt || 'Pocketworks'}
                />
              </div> */}

            {logos()}


          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

function logos() {
  return (
    <><Columns className="is-vcentered is-centered ">
      <Columns.Column className="is-offset-0 is-12-desktop is-12-mobile pb-6 mb-6">
        <Columns className="is-vcentered has-text-centered is-mobile">
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/asda-price-guarantee-app">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558154-asda-logo-light.png?monochrome=999999&bri=-10&h=160"
                alt="ASDA - Pocketworks"
                placeholder="tracedSVG"
                height={35} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/fresenius">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558276-fresenius-logo-light.png?monochrome=999999&bri=-20&h=180"
                alt="Fresenius - Pocketworks"
                className=""
                placeholder="tracedSVG"
                height={40} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/wastecare">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1710772962-wastecare-logo.png?monochrome=999999&h=80&bri=22&h=180"
                alt="WasteCare - Pocketworks"
                className="m-1"
                placeholder="tracedSVG"
                height={35} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/carbs-and-cals/">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1714918076-carbs.png?monochrome=999999&h=162"
                alt="Carbs & Cals - Pocketworks"
                className=""
                placeholder="tracedSVG"
                height={35} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/travelodge-prototypes">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558227-travelodge-logo-light.png?monochrome=999999&bri=-20&h=180"
                alt="Travelodge - Pocketworks"
                className="m-1"
                placeholder="tracedSVG"
                height={35} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/veezu-taxi-booking-mobile-apps">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120597-veezu-logo.png?monochrome=999999&bri=-10&h=100"
                alt="Veezu - Pocketworks"
                placeholder="tracedSVG"
                height={26} />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile is-hidden">
            <Link to="/blog/krone-uk-selects-pocketworks">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1663790818-krone-logo.png?bri=-20&monochrome=999999&bri=5&h=80&fm=png"
                alt="Krone - Pocketworks"
                className="p-2"
                placeholder="tracedSVG"
                objectFit="contain"
                height={36} />
            </Link>
          </Columns.Column>
        </Columns>
      </Columns.Column>
    </Columns>
      <Columns className="is-centered is-size-6">
        <Columns.Column className="has-text-left is-6 is-flex">
          <Card className="classy has-bg-white is-flex is-flex-grow-1 h-100 is-fullwidth">
            <Link
              to="/blog/using-claude-for-goal-driven-agentic-mobile-testing/"
            >
              Leatest Article<br />Using Claude for agentic goal-driven mobile testing.
            </Link>
          </Card>
        </Columns.Column>
        <Columns.Column className="has-text-left is-6 is-flex">
          <Card className="classy has-bg-white is-flex is-flex-grow-1 h-100 is-fullwidth">
            <Link
              to="https://www.tickettailor.com/events/greentechgathering/1478474?#:~:text=To%20set%20the%20pace%20of,person%2C%20share%20knowledge%20and%20collaborate"
            >
              See Us Talk<br />Opportunities and ethical challenges of integrating AI into user research.
            </Link>
          </Card>

        </Columns.Column>
      </Columns></>
  )
}

HeroComponent.propTypes = {
  featuredMedia: PropTypes.object,
  data: PropTypes.object,
}

export default HeroComponent
