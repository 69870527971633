import PropTypes from 'prop-types'
import React from 'react'

function ArticleContent({ data }) {
  return (
    <div
      id="article-top"
      className="blog-text pt-6"
      dangerouslySetInnerHTML={{
        __html: data.page.contentNode.childMarkdownRemark
          ? data.page.contentNode.childMarkdownRemark.html
          : data.page.content,
      }}
    />
  )
}

ArticleContent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ArticleContent
