/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import ArticleTemplate from 'routes/templates/article'

// Meta Component
import Meta from 'components/Meta'

// ArticleTemplate Route
export default ArticleTemplate

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query ArticleQuery($slug: String!, $catSlug: String!) {
    page: datoCmsArticle(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      originalId
      title
      slug
      date(formatString: "MMMM D, YYYY")
      meta {
        publishedAt(formatString: "MMMM D, YYYY")
        firstPublishedAt(formatString: "MMMM D, YYYY")
      }
      excerpt
      content
      contentNode {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 100)
        }
      }
      featuredMedia {
        url
        gatsbyImageData(
          placeholder: BLURRED
          imgixParams: {
            fm: "jpg"
            auto: "compress"
            h: "700"
            fit: "crop"
            ar: "21:9"
            crop: "edges"
            duotone: "f99f53,FA8072"
            duotoneAlpha: 6
          }
        )
        url
      }
      author {
        name
        firstName
        role
        slug
        linkedinUrl
        mainImage {
          gatsbyImageData(
            placeholder: NONE
            imgixParams: {
              fm: "png"
              auto: "compress"
              fit: "facearea"
              facepad: 3.0
              maxW: 200
            }
          )
          url
        }
      }
      categories {
        name
        slug
      }
    }
    articles: allDatoCmsArticle(
      filter: { categories: { elemMatch: { slug: { eq: $catSlug } } } }
      limit: 6
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "MMMM D, YYYY")
          title
          slug
        }
      }
    }
  }
`
