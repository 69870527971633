import React, { useState, useCallback, useEffect } from 'react'
import { debounce } from 'lodash'
import { Section, Container, Columns, Box } from 'react-bulma-components'
// Components
import Separator from 'components/separator'

function ContactForm() {
  const [validationMessage, setValidationMessage] = useState('')
  const [formData, setFormData] = useState({
    message: '',
    email: '',
    name: '',
    position: '',
    telephone: '',
    budget: '',
  })

  const validateInput = async (message, email) => {
    if (!message || message.length < 10) {
      setValidationMessage([]) // Clear hints if message is too short
      return
    }

    try {
      setValidationMessage([]) // Clear hints before making the request
      const response = await fetch('/.netlify/functions/validate-enquiry', {
        method: 'POST',
        body: JSON.stringify({
          message,
          email,
          name: formData.name,
          position: formData.position,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()
      if (data.suggestions && Array.isArray(data.suggestions)) {
        setValidationMessage(data.suggestions)
      } else {
        setValidationMessage([]) // Clear hints if response is invalid
      }
    } catch (error) {
      console.error('Validation error:', error)
      setValidationMessage([]) // Clear hints on error
    }
  }

  // Create a debounced version of validateInput
  const debouncedValidate = useCallback(
    debounce((message, email) => validateInput(message, email), 2000),
    [] // Empty dependency array since we don't want to recreate the debounced function
  )

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (name === 'message' || name === 'email') {
      debouncedValidate(
        name === 'message' ? value : formData.message,
        name === 'email' ? value : formData.email
      )
    }
  }

  // Cleanup debounce on unmount
  useEffect(
    () => () => {
      debouncedValidate.cancel()
    },
    [debouncedValidate]
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.target.submit()
  }

  return (
    <Section className="has-background-light is-medium pt-3">
      <Container className="content">
        <Columns className="is-centered">
          <Columns.Column className="is-7">
            <Box className="p-6 is-shadowed is-rounded">
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="/contact/thanks"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <h3 className="title has-text-centered pb-5 pt-0">
                  Your app enquiry
                </h3>
                <p className="is-hidden">
                  <label>
                    Don’t fill this out if you’re human:{' '}
                    <input name="bot-field" />
                  </label>
                </p>
                <Columns>
                  <Columns.Column className="is-6">
                    <div className="field">
                      <label className="label">
                        Your name <span className="has-text-primary">*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="name"
                          required
                          onChange={handleInputChange}
                          value={formData.name}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column className="is-6">
                    <div className="field">
                      <label className="label">
                        Your email <span className="has-text-primary">*</span>
                      </label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="email"
                          name="email"
                          required
                          onChange={handleInputChange}
                          value={formData.email}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <div className="field">
                      <label className="label">Your position</label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="position"
                          onChange={handleInputChange}
                          value={formData.position}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <div className="field">
                      <label className="label">Your phone number</label>
                      <div className="control">
                        <input
                          className="input is-success"
                          type="text"
                          name="telephone"
                          onChange={handleInputChange}
                          value={formData.telephone}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>

                <div className="field mt-3">
                  <label className="label">How can we help you?</label>
                  <div className="control">
                    <textarea
                      rows={10}
                      className="textarea is-success"
                      name="message"
                      required
                      onChange={handleInputChange}
                      value={formData.message}
                    />
                  </div>
                  {validationMessage && validationMessage.length > 0 && (
                    <article className="message is-info">
                      <div className="message-body">
                        <p>
                          <i class="fa-solid fa-robot" /> PocketBot Says
                          (optional):
                        </p>
                        <ul className="mt-2">
                          {validationMessage.map((hint, index) => (
                            <li key={index} className="mb-2">
                              {hint}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </article>
                  )}
                </div>

                <div className="field mt-0">
                  <label className="label">
                    How much do you expect to invest?
                  </label>

                  <div className="control">
                    <label className="radio pr-1">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="Not Sure"
                        onChange={handleInputChange}
                        checked={formData.budget === 'Not Sure'}
                      />
                      Not sure
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£100k+"
                        onChange={handleInputChange}
                        checked={formData.budget === '£100k+'}
                      />
                      &pound;100K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£200k+"
                        onChange={handleInputChange}
                        checked={formData.budget === '£200k+'}
                      />
                      &pound;200K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£500k+"
                        onChange={handleInputChange}
                        checked={formData.budget === '£500k+'}
                      />
                      &pound;500K+
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="budget"
                        className="pr-1 mr-1"
                        value="£1M+"
                        onChange={handleInputChange}
                        checked={formData.budget === '£1M+'}
                      />
                      &pound;1M+
                    </label>
                  </div>
                </div>

                <p className="has-text-centered mt-6">
                  <button
                    className="button is-primary is-leafy is-medium is-flex"
                    type="submit"
                  >
                    Send my enquiry{' '}
                    <span className="icon ml-2 mt-1">
                      <i className="fas fa-arrow-right fa-sm" />
                    </span>
                  </button>
                </p>
              </form>
            </Box>
          </Columns.Column>
        </Columns>
        <Separator className="is-hidden-mobile mt-6" rtl />
      </Container>
    </Section>
  )
}

export default ContactForm
