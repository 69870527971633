import PropTypes from 'prop-types'
import React, { useEffect, useState, setState, createElement } from 'react'
import { Section, Container, Columns } from 'react-bulma-components'
import Link from 'gatsby'

// Components
import Layout from 'components/layout'
import SiteNav from 'components/navbar'

// Page Components
import ArticleHead from './components/article-head'
import AuthorImage from './components/author-image'
// import AboutAuthor from './components/about-author'
import ArticleContent from './components/article-content'
import AboutPocketworks from './components/about-pocketworks'
import GrowKnowledge from './components/grow-knowledge'
import AlsoLike from './components/also-like/AlsoLike'

// Hooks
import useKeys from '../../../hooks/useKeys'

// Lazy Load Disqus Component
const Disqus = React.lazy(() => import('./components/disqus'))
const titles = []
class ArticleComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      pageContext: props.pageContext,
      titles: [],
    }

    // useKeys(editArticleHandler)
    // useEffect(tocHandler)
  }

  // Edit Article Using Shortcut Alt + K
  // editArticleHandler = () => {
  //   const url = `https://pocketworks-web.admin.datocms.com/editor/item_types/183647/items/${this.state.data.page.originalId}`
  //   window.open(url)
  // }

  componentDidMount() {
    // Build the table of contents
    let nodeList = document.querySelectorAll('.blog-text h2')
    if (nodeList.length === 0) {
      nodeList = document.querySelectorAll('.blog-text h3')
    }
    const tmp = []
    for (let i = 0; i < nodeList.length; i += 1) {
      tmp.push(nodeList[i])
      nodeList[i].id = nodeList[i].innerText
    }
    if (tmp.length > 0) {
      this.setState({ titles: tmp })
    }

    // Bring the newsletter subscribe box higher up in the content
    const newsletterBox = document.querySelector(
      '#gatsby-focus-wrapper > div > div > section.section.pt-0 > div > div > div.is-offset-1.is-6-desktop.is-four-fifths-tablet.is-12-mobile.column > div.box'
    )
    if (false && newsletterBox != null && nodeList.length > 2) {
      // document.removeChild(newsletterBox)
      const paragraph = document.createElement('p')
      const bold = document.createElement('b')
      const content = document.createTextNode('Oh, a quick interruption! ')
      bold.append(content)
      paragraph.append(bold)
      paragraph.append(
        document.createTextNode(
          "If you like this article, you'll probably like our newsletter? It brings you loads of useful mobile insights and tips, helping you incrase your mobile knowledge every two weeks 👇"
        )
      )
      const after = document.createElement('p')
      after.append(
        document.createTextNode(
          "Righty, now that you've signed up, let's get back to the article 😅"
        )
      )
      nodeList[2].parentElement.insertBefore(paragraph, nodeList[2])
      nodeList[2].parentElement.insertBefore(newsletterBox, nodeList[2])
      nodeList[2].parentElement.insertBefore(after, newsletterBox.nextSibling)
    }
  }

  render() {
    return (
      <Layout>
        <div className="article">
          <SiteNav fullLogo />
          <ArticleHead data={this.state.data} />
          <AuthorImage data={this.state.data} />
          <Section className="pt-0">
            <Container className="content is-widescreen">
              <Columns className="">
                <Columns.Column className="is-offset-1 is-6-desktop is-four-fifths-tablet is-12-mobile">
                  <ArticleContent data={this.state.data} />
                  <AboutPocketworks />
                  <GrowKnowledge />
                  <div className="mt-5">
                    <React.Suspense fallback="">
                      <Disqus page={this.state.data.page} />
                    </React.Suspense>
                  </div>
                </Columns.Column>
                <Columns.Column className="is-offset-1 mt-5">
                  <div
                    className={`ml-6 box classy is-rounded has-background-grey-lighter pt-6 pr-6 pl-5 pb-6 ${
                      this.state.titles.length > 0 ? '' : 'is-hidden'
                    }`}
                  >
                    <h5 className="title is-size-6 has-text-weight-light has-text-grey">
                      Contents
                    </h5>
                    <ol className="boxc is-size-6">
                      <li>
                        <a href="#article-top">Intro</a>
                      </li>
                      {this.state.titles.map((title) => (
                        <li key={title}>
                          <a href={`#${title.innerText}`}>{title.innerText}</a>
                        </li>
                      ))}
                    </ol>
                  </div>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
          <Section>
            <Columns className="is-centered is-widescreen">
              <Columns.Column className="is-6-desktop is-offset-1 is-four-fifths-tablet is-flex-mobile">
                <div className="box is-hidden">
                  <React.Suspense fallback="">
                    <Disqus page={this.state.data.page} />
                  </React.Suspense>
                </div>
                <AlsoLike
                  fallbackArticles={this.state.data}
                  relatedArticles={
                    this.state.pageContext?.relatedArticles || []
                  }
                />
              </Columns.Column>
            </Columns>
          </Section>
        </div>
      </Layout>
    )
  }
}

ArticleComponent.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default ArticleComponent
