// Dependencies
import React, { useState, useEffect } from 'react'
import {
  Section,
  Container,
  Content,
  Box,
  Card,
  Heading,
  Columns,
  Hero,
} from 'react-bulma-components'
import Layout from 'layout'
import SiteNav from 'components/navbar'
import styles from './SustainableMobileUsage.module.css'
import data from './sustainabilityData.json'

// Client-side only component
const Plot = React.lazy(() => import('react-plotly.js'))

// Component to render Plot only on client-side
function ClientSidePlot(props) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return <div>Loading chart...</div>
  }

  return (
    <React.Suspense fallback={<div>Loading chart...</div>}>
      <Plot {...props} />
    </React.Suspense>
  )
}

function createComparisonChart(data, title) {
  return {
    data: [
      {
        type: 'bar',
        x: data.Activity,
        y: data.CO2_Emissions,
        text: data.Comparison,
        textposition: 'outside',
      },
    ],

    layout: {
      title,
      yaxis: {
        type: 'log',
        title: 'CO2 Emissions (g)',
      },
      xaxis: {
        title: 'Activity',
      },
      height: 500,
      margin: { b: 100 },
    },
    config: {
      displayModeBar: false,
    },
  }
}

function Recommendations({ category, tabTitles, categories }) {
  return (
    <div>
      <h4 className="is-size-5 has-text-weight-medium mb-2">
        Sustainable {tabTitles[categories.indexOf(category)]} Habits
      </h4>
      <Content>
        <ul>
          {data[category].recommendations.tips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </Content>
    </div>
  )
}

function GeneralTips() {
  return (
    <div>
      <h2 className="title">
        General Tips to Reduce Your Digital Carbon Footprint
      </h2>
      <Columns>
        <Columns.Column>
          <Content>
            <ul>
              <li>
                Use dark mode when possible to reduce screen energy consumption
              </li>
              <li>
                Download content for offline use instead of streaming repeatedly
              </li>
              <li>Reduce video quality when high resolution isn't necessary</li>
              <li>Use Wi-Fi instead of mobile data when possible</li>
              <li>Clear unused files and emails regularly</li>
            </ul>
          </Content>
        </Columns.Column>
        <Columns.Column>
          <Content>
            <ul>
              <li>Enable power-saving modes on your devices</li>
              <li>Use browser bookmarks instead of repeated searches</li>
              <li>Compress files before sharing</li>
              <li>Unsubscribe from unnecessary email newsletters</li>
              <li>Close unused browser tabs and applications</li>
            </ul>
          </Content>
        </Columns.Column>
      </Columns>
    </div>
  )
}

function SustainableMobileUsage() {
  const [activeTab, setActiveTab] = useState(0)
  const categories = ['social', 'streaming', 'crypto', 'email', 'ai', 'ml']
  const tabTitles = [
    'Social Media',
    'Streaming',
    'Cryptocurrency',
    'Emails',
    'AI',
    'Machine Learning',
  ]

  return (
    <Layout>
      <Hero className="has-background-grey-lighter is-medium">
        <Hero.Header>
          <SiteNav fullLogo />
        </Hero.Header>
        <Hero.Body>
          <Container className="content is-widescreen">
            <div className="columns content is-vcentered is-multiline">
              <div className="column is-9">
                <h1 className="title is-size-6 is-outlined pt-2 mt-0 mb-0">
                  Digital Sustainability
                </h1>
                <h2 className="title is-size-1-widescreen is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text has-text-grey-darker">
                  <dfn title="A dahboard visualising the environmental impact of our digital activities">
                    <span className="">
                      Reduce Your Mobile Carbon Footprint
                    </span>
                  </dfn>
                </h2>
                <p className="is-size-4">
                  With increasing social media, AI and other digital trends, our
                  digital carbon footprint is on the rise. We created this tool
                  to help employees and individuals be more mindful of how they
                  use apps and digital technologies in their lives.
                </p>
                <p>
                  If you like it, please share with your team or network on
                  LinkedIn or X.
                </p>
                <p>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                      typeof window !== 'undefined' ? window.location.href : ''
                    )}&title=${encodeURIComponent(
                      'Mobile Carbon Footprint Dashboard - Reduce Your Digital Carbon Footprint'
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-text is-light"
                  >
                    <i className="fab fa-linkedin mr-2" />
                    Share on LinkedIn
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      typeof window !== 'undefined' ? window.location.href : ''
                    )}&text=${encodeURIComponent(
                      'Check out this Mobile Carbon Footprint Dashboard to see how you can reduce your digital carbon footprint!'
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-text is-light ml-2"
                  >
                    <i className="fab fa-x-twitter mr-2" />
                    Share on X
                  </a>
                </p>
                <article className="message is-dark">
                  <div class="message-body">
                    Note: This is a beta version, a work in progress. It's a
                    little clunky and we need to do a bit more fact checking.
                    But hopefully you'll find it useful!
                  </div>
                </article>
              </div>
              <div className="column is-3">
                <img
                  src="https://www.datocms-assets.com/20955/1658568255-mobile-strategy-illustration.png"
                  alt="Digital sustainability illustration"
                  className="hero-image"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    transform: 'scale(1.1)',
                  }}
                />
              </div>
            </div>
          </Container>
        </Hero.Body>
      </Hero>

      <Section>
        <Container className="content">
          <h2 className="title has-text-centered pb-5">
            How to reduce your mobile carbon footprint
          </h2>
          <div className="tabs is-centered is-boxed mb-0 fix-align">
            <ul>
              {tabTitles.map((title, index) => (
                <li
                  key={index}
                  className={
                    activeTab === index ? 'is-active  mr-0 mb-0' : 'mb-0 mr-0'
                  }
                >
                  <a
                    style={{ 'padding-bottom': '-1px' }}
                    onClick={() => setActiveTab(index)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setActiveTab(index)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {categories.map((category, index) => (
            <div
              key={index}
              style={{ display: activeTab === index ? 'block' : 'none' }}
            >
              <Box
                className="mt-0 is-rounded"
                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              >
                <div className="message is-info">
                  <div className="message-body">{data[category].tip}</div>
                </div>
                <Columns>
                  <Columns.Column size={8}>
                    <ClientSidePlot
                      {...createComparisonChart(
                        data[category].data,
                        `${tabTitles[index]} Carbon Emissions`
                      )}
                      useResizeHandler
                      style={{ width: '100%' }}
                      layout={{
                        ...createComparisonChart(
                          data[category].data,
                          `${tabTitles[index]} Carbon Emissions`
                        ).layout,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                      }}
                    />
                  </Columns.Column>
                  <Columns.Column size={4}>
                    <Recommendations
                      category={category}
                      tabTitles={tabTitles}
                      categories={categories}
                    />
                  </Columns.Column>
                </Columns>
              </Box>
            </div>
          ))}
        </Container>
      </Section>
      <Section className="content">
        <Container>
          <Card className="p-6 classy has-bg-white has-shadow">
            <span className="circle is-top-right" />
            <GeneralTips />
          </Card>
        </Container>
      </Section>
      <Section className="content">
        <Container>
          <Box className="p-6 is-rounded">
            <h2 className="title">Complete Overview: All Digital Activities</h2>
            <Content>
              <p>
                Compare the environmental impact of all digital activities in a
                single view. Activities are sorted by CO2 emissions to highlight
                the most significant contributors.
              </p>
            </Content>
            <ClientSidePlot
              {...createComparisonChart(
                {
                  Activity: Object.values(data).flatMap((d) => d.data.Activity),
                  CO2_Emissions: Object.values(data).flatMap(
                    (d) => d.data.CO2_Emissions
                  ),
                  Comparison: Object.values(data).flatMap(
                    (d) => d.data.Comparison
                  ),
                },
                'Digital Activities Ranked by CO2 Emissions'
              )}
              useResizeHandler
              style={{ width: '100%' }}
              layout={{
                ...createComparisonChart(
                  {
                    Activity: Object.values(data).flatMap(
                      (d) => d.data.Activity
                    ),
                    CO2_Emissions: Object.values(data).flatMap(
                      (d) => d.data.CO2_Emissions
                    ),
                    Comparison: Object.values(data).flatMap(
                      (d) => d.data.Comparison
                    ),
                  },
                  'Digital Activities Ranked by CO2 Emissions'
                ).layout,
                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
              }}
            />
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default SustainableMobileUsage
